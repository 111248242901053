export default {
  methods: {
    // https://github.com/vuejs/vue/issues/1829#issuecomment-624193371
    // data attributes ändern, ohne watchers zu triggern (vue3) - change data attributes without triggering watchers (vue3)
    $withoutWatchers(cb) {
      let originalWatchers = {}

      if (!this._.type.watch) {
        cb()
        return
      }

      // no structured clone because we only need the reference
      for (let [key, watcher] of Object.entries(this._.type.watch)) {
        originalWatchers[key] = watcher
      }

      this._.type.watch = {}

      cb()

      // restore watchers
      this._.type.watch = Object.assign(this._.type.watch, originalWatchers)
    },
  },
}
