import inbox from "./modules/inbox"
import searchFilter from "./modules/search-filter"
import lastVisited from "./modules/last-visited"
import { createStore as _createStore } from "vuex"

export function createStore() {
  return _createStore({
    modules: {
      inbox,
      searchFilter,
      lastVisited,
    },
  })
}
