import Pusher, { Channel } from "pusher-js"

export interface PusherAPI {
  init: (channel: string) => void
  subscribe: (channelName: string) => void
  unsubscribe: (channelName: string) => void
  on: (channelName: string, cb: any) => void
  off: (channelName: string) => void
  trigger: (eventName: string, data: any) => void
}

export default {
  install(app, _) {
    try {
      const pusherKey = document.querySelector<HTMLMetaElement>("meta[name=pusher-key]")?.content
      const csrfToken = document.querySelector<HTMLMetaElement>("meta[name=csrf-token]")?.content
      if (!pusherKey || !csrfToken) {
        console.warn("Pusher not configured correctly, skipping realtime setup")
        throw new Error("Pusher not configured correctly")
      }
      const pusher = new Pusher(pusherKey, {
        cluster: "eu",
        authEndpoint: "/api/v1/pusher/auth",
        auth: {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        },
      })
      const subscribed = false
      let shopChannelName: string | null = null
      let shopChannel: Channel | null = null

      const $pusher: PusherAPI = {
        init: channel => {
          if (subscribed || shopChannelName === channel) return
          try {
            shopChannel = pusher.subscribe(channel)
            pusher.subscribe(`${channel.split("-").slice(0, -1).join("-")}-global`)
            shopChannelName = channel
          } catch (e) {
            console.warn("could not initialize pusher API", e)
          }
        },
        subscribe: channelName => {
          return pusher.subscribe(channelName)
        },
        unsubscribe: channelName => {
          return pusher.unsubscribe(channelName)
        },
        on: (channelName, cb) => {
          pusher.bind(channelName, cb)
        },
        off: channelName => {
          pusher.unbind(channelName)
        },
        trigger: (eventName, data) => {
          shopChannel?.trigger(eventName, data)
        },
      }
      app.config.globalProperties.$pusher = $pusher
    } catch (error) {
      console.log("Unfortunately something crashed when connecting to the realtime server, see this:", error)
    }
  },
}

declare module "vue" {
  interface ComponentCustomProperties {
    $pusher: PusherAPI
  }
}
