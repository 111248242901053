import { ElMessageBox } from "element-plus"

export default {
  install(app) {
    const message = (message, type, dangerouslyUseHTMLString = true) => {
      app.config.globalProperties.$message({
        showClose: true,
        dangerouslyUseHTMLString,
        message,
        type,
        duration: type == "error" ? 3000 : 1500,
      })
    }

    const $flashy = (msg, type = "success") => {
      message(msg, type)
    }

    const $alert = msg => {
      message(msg, "error")
    }

    const $warn = (options, cb, catchCb = () => {}) => {
      return ElMessageBox.confirm(options.desc, options.title, {
        confirmButtonText: options.confirmButtonText,
        cancelButtonText: options.cancelButtonText || app.config.globalProperties.$t?.("warnPlugin"),
        type: "warning",
        confirmButtonClass: options.redButton
          ? "bg-red-500 border-red-500 hover:bg-red-400 hover:border-red-400 focus:bg-red-400 focus:border-red-400"
          : undefined,
      })
        .then(cb)
        .catch(catchCb)
    }

    app.config.globalProperties.$flashy = $flashy
    app.config.globalProperties.$alert = $alert
    app.config.globalProperties.$warn = $warn

    // expose global functions to window as helpers
    window.App.info = (title, message) => {
      app.config.globalProperties.$notify.info({ title, message, duration: 10000 })
    }

    window.App.flashy = (msg, type) => {
      message(msg, type == "danger" ? "error" : "success", false)
    }

    window.App.alert = msg => {
      message(msg, "error", false)
    }

    if (window.alertMessage) {
      message(window.alertMessage, "error", false)
      window.alertMessage = undefined
    }

    if (window.successMessage) {
      message(window.successMessage, "success", false)
      window.successMessage = undefined
    }
  },
}

declare module "vue" {
  interface ComponentCustomProperties {
    $alert: (msg: string) => void
    $flashy: (msg: string, type?: "success" | "error" | "warning") => void
    $warn: (obj: object, fnct: Function) => void
  }
}

declare global {
  interface Window {
    alertMessage?: string
    successMessage?: string
    App: {
      info: (title: string, msg: string) => void
      flashy: (msg: string, type: string) => void
      alert: (msg: string) => void
    }
  }
}
