export default {
  install(app, { auth }) {
    app.directive("turbo", el => {
      el.addEventListener("click", e => {
        e.preventDefault()
        e.stopPropagation()
        Turbolinks.visit(el.href)
      })
    })

    app.directive("external-link", (el, options) => {
      el.addEventListener("click", e => {
        e.preventDefault()
        e.stopPropagation()
        window.open(options.value)
      })
    })

    app.directive("jump-scroll", (el, options) => {
      el.addEventListener("click", e => {
        e.preventDefault()
        document.getElementById(options.value)?.scrollIntoView({ behavior: "smooth" })
      })
    })

    app.directive("click-outside", {
      mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            binding.value(event)
          }
        }
        document.body.addEventListener("click", el.clickOutsideEvent)
      },
      unmounted: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent)
      },
    })

    app.directive("redacted", function (el, binding) {
      el.dataset.hjSuppress = true
      if (auth.isBackdoor()) {
        if (!(binding.modifiers.keepText && (binding.value === undefined || binding.value === true)))
          el.innerHTML = "Redacted"
        if (el.hasAttribute("href")) {
          el.href = "javascript:;"
          el.addEventListener("click", e => {
            e.preventDefault()
            App.alert("Aktion nicht erlaubt durch Support")
          })
        }
      }
    })
  },
}
